<template>
<div class="topbar-item">
  <div
    class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
    id="kt_quick_user_toggle"
  >
    <span
      class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
    >
      {{ $t('USER_PROFILE.HI') }},
    </span>
    <span
      class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
    >
      {{ $store.state.auth.user ? $store.state.auth.user.firstName : "" }}
    </span>
    <span class="symbol symbol-35 symbol-light-success">
      <img v-if="$store.state.auth.user.avatar" alt="Pic" :src="imgUrl($store.state.auth.user.avatar.hrefThumbnail)">
      <span v-else class="symbol-label font-size-h5 font-weight-bold">
        {{ $store.state.auth.user && $store.state.auth.user.firstName ? $store.state.auth.user.firstName.charAt(0) : '?' }}
      </span>
    </span>
  </div>

  <div
    id="kt_quick_user"
    ref="kt_quick_user"
    class="offcanvas offcanvas-right p-10"
  >
    <!--begin::Header-->
    <div
      class="offcanvas-header d-flex align-items-center justify-content-between pb-5 mt-9"
    >
      <h3 class="font-weight-bold m-0">
        {{ $t('USER_PROFILE.TITLE') }}
        <!--<small class="text-muted font-size-sm ml-2">12 messages</small>-->
      </h3>
      <div
        class="btn btn-xs btn-icon btn-light-secondary"
        id="kt_quick_user_close"
      >
        <span class="svg-icon svg-icon-secondary svg-icon-lg">
          <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
        </span>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Content-->
    <perfect-scrollbar
      class="offcanvas-content pr-5 mr-n5 scroll"
      style="max-height: 90vh; position: relative;"
    >
      <!--begin::Header-->
      <div class="d-flex align-items-center mt-5">
        <div class="symbol symbol-100 mr-5">
          <img v-if="$store.state.auth.user.avatar" class="symbol-label" :src="imgUrl($store.state.auth.user.avatar.href)" alt="">
          <span v-else class="symbol-label font-size-h5 font-weight-bold">
            {{ $store.state.auth.user && $store.state.auth.user.firstName ? $store.state.auth.user.firstName.charAt(0) : '?' }}
          </span>
          <i class="symbol-badge bg-success" />
        </div>
        <div class="d-flex flex-column">
          <span class="font-weight-bold font-size-h5 text-dark-75">
            {{ $store.state.auth.user ? $store.state.auth.user.firstName + ' ' + $store.state.auth.user.lastName : "" }}
          </span>
          <!--<div class="text-muted mt-1">Application Developer</div>-->
          <div class="navi mt-2">
            <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="/media/svg/icons/Communication/Mail-notification.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="navi-text text-muted text-hover-primary">
                  {{ $store.state.auth.user ? $store.state.auth.user.email : "" }}
                </span>
              </span>
            </a>
          </div>
          <div>
            <button class="btn btn-light-dark font-weight-bold" @click="onLogout">
              <span class="svg-icon btn-light-dark">
                <inline-svg src="/media/svg/icons/Home/Door-open.svg" />
              </span>
              {{ $t('AUTH.LOGOUT.BUTTON') }}
            </button>
          </div>
        </div>
      </div>
      <!--end::Header-->
    </perfect-scrollbar>
    <!--end::Content-->
  </div>
</div>
</template>

<script>
import { LOGOUT } from '@/core/services/store/auth.module'
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js'

export default {
  name: 'KTQuickUser',
  computed: {
    imgUrl() {
      return (href) => `${process.env.VUE_APP_BACKEND_ORIGIN}/${href}`
    },
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs.kt_quick_user)
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
    },
  },
}
</script>

<style lang='scss' scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>
