<template>
<!-- begin:: Header Topbar -->
<div class="topbar">
  <!--begin: Search -->
  <b-dropdown
    v-if="!pagination.isEmpty()"
    id="kt_quick_search_toggle"
    no-caret
    no-flip
    right
    size="sm"
    toggle-class="topbar-item text-decoration-none"
    variant="link"
  >
    <template #button-content>
      <div class="btn btn-icon btn-clean btn-lg btn-dropdown mr-1 pulse pulse-primary symbol symbol-circle symbol-50 ml-3"
           @click.prevent="toggleReminder"
      >
        <span class="svg-icon svg-icon-xl " :class="{'svg-icon-primary':unreadCount > 0}">
          <inline-svg src="/media/svg/icons/General/Notifications1.svg" />
        </span>
        <span v-if="unreadCount > 0" class="pulse-ring" />
        <i class="symbol-badge bg-danger" v-if="unreadCount > 0" />
      </div>
    </template>
  </b-dropdown>
  <!--end: Search -->
  <b-dropdown
    ref="dropdownCurrency"
    lazy
    no-caret
    no-flip
    right
    size="sm"
    toggle-class="topbar-item text-decoration-none"
    variant="link"
  >
    <template #button-content>
      <div
        class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 "
      >
        <span class="svg-icon svg-icon-xl svg-icon-info">
          <inline-svg src="/media/svg/icons/Shopping/Chart-bar1.svg" />
        </span>
        <span class="pulse-ring" />
      </div>
    </template>
    <b-dropdown-text class="min-w-md-350px" tag="div">
      <form>
        <KTDropdownNotification />
      </form>
    </b-dropdown-text>
  </b-dropdown>
  <!--begin: Language bar -->
  <div class="topbar-item">
    <b-dropdown
      no-caret
      no-flip
      right
      size="sm"
      toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
      variant="link"
    >
      <template #button-content>
        <img
          :src="languageFlag || getLanguageFlag"
          alt=""
          class="h-20px w-20px rounded-sm"
        >
      </template>
      <b-dropdown-text class="min-w-md-175px" tag="div">
        <KTDropdownLanguage
          @language-changed="onLanguageChanged"
        />
      </b-dropdown-text>
    </b-dropdown>
  </div>
  <!--end: Language bar -->

  <!--begin: User Bar -->
  <KTQuickUser />
  <quick-panel v-model="reminderVisible">
    <template #title>{{ $t('BASE.REMINDER_LIST') }}</template>
    <template #body>
      <div :class="{overlay:$loading}" class="navi overlay-block navi-icon-circle navi-spacer-x-0">
        <div class="overlay-wrapper">
          <a v-for="reminder in pagination.items" :key="reminder.uuid" class="navi-item" href="#">
            <div class="navi-link rounded">
              <div class="symbol symbol-50 mr-3">
                <div class="symbol-label">
                  <span
                    :class="[reminder.isViewed() ? 'svg-icon-muted':`svg-icon-${reminder.label.color}`, 'svg-icon svg-icon-xl']"
                  >
                    <inline-svg :src="`/media/svg/icons/${reminder.label.icon}`" />
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div :class="{'text-muted': reminder.isViewed()}" class="font-weight-bold font-size-lg">
                  {{ reminder.text }}
                </div>
                <div class="text-muted"> {{ reminder.event }}</div>
              </div>
              <div class="symbol symbol-50 mr-3">
                <a class="btn btn-icon btn-light btn-sm" href="#" @click.prevent="toggleViewed(reminder)">
                  <span :class="[reminder.isViewed() ? 'svg-icon-muted':'svg-icon-success', 'svg-icon svg-icon-xl']">
                    <inline-svg v-if="reminder.isViewed()" src="/media/svg/icons/Navigation/Double-check.svg" />
                    <inline-svg v-else src="/media/svg/icons/Navigation/Check.svg" />
                  </span>
                </a>
              </div>
            </div>
          </a>
          <div>
            <button
              :class="{disabled:pagerStopped||$loading}"
              class="btn btn-success btn-sm btn-shadow-hover font-weight-bolder w-100 py-3 btn-secondary"
              type="button"
              @click.prevent="load"
            >
              {{ $t('MORE') }}
            </button>
          </div>
          <div v-if="$loading" class="overlay-layer bg-dark-o-10">
            <div class="spinner spinner-primary" />
          </div>
        </div>
      </div>
    </template>
  </quick-panel>
</div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownNotification from '@/view/layout/extras/dropdown/DropdownNotification.vue'
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue'
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue'
import i18nService from '@/core/services/i18n.service'
import Repo from '@/core/repository/company/reminderRepository'
import Pagination from '@/model/pagination'

export default {
  name: 'KTTopbar',
  components: {
    KTDropdownNotification,
    KTDropdownLanguage,
    KTQuickUser,
  },
  data() {
    return {
      reminderVisible: false,
      pagination: new Pagination(),
      pagerStopped: false,
      pagerLoading: false,
      unreadCount: 0,
      query: {
        pagination: {
          page: 1,
          limit: 7,
        },
      },
      languageFlag: '',
      languages: i18nService.languages,
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged()
    },
  },
  created() {
    this.load()
    setInterval(this.load, 60000)
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find((val) => {
        return val.lang === i18nService.getActiveLanguage()
      }).flag
    },
    toggleReminder() {
      this.reminderVisible = !this.reminderVisible
    },
    fetchUnreadCount() {
      Repo.paginate({
        unread: true,
        pagination: { limit: 1 },
      })
        .then(({ totalCount }) => {
          this.unreadCount = totalCount
        })
        .catch(console.log)
    },
    load() {
      if (this.$loading || this.pagerStopped) {
        return
      }
      this.loadingOn()
      Repo.paginate(this.query)
        .then((pagination) => {
          pagination.items.unshift(...this.pagination.items)
          this.pagination = pagination
          if (this.pagination.pageCount > this.query.pagination.page) {
            this.query.pagination.page++
          } else {
            this.pagerStopped = true
          }
        })
        .then(this.fetchUnreadCount)
        .catch(console.log)
        .finally(this.loadingOff)
    },
    toggleViewed(reminder) {
      Repo.save(reminder.toggleViewed())
        .then(this.$toastSuccess('TOAST.SUCCESS'))
        .then(this.fetchUnreadCount)
        .catch(this.$toastDanger('TOAST.ERROR'))
    },
  },
}
</script>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
