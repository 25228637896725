<template>
<router-link
  v-slot="{ href, navigate, isActive, route, isExactActive }"
  :to="{ name: nameRoute }"
  :exact="exact"
>
  <li
    :class="[
      isActive && 'menu-item-active',
      isExactActive && 'menu-item-active',
      route.path === $route.path && 'menu-item-active',
    ]"
    @click="onClick"
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item"
  >
    <a
      :href="href"
      class="menu-link"
      @click="navigate"
    >
      <i
        v-if="subLink"
        class="menu-bullet menu-bullet-dot"
      />
      <span
        v-else
        class="svg-icon menu-icon"
      >
        <inline-svg :src="icon" />
      </span>
      <span class="menu-text">
        {{ title }}
      </span>
    </a>
  </li>
</router-link>
</template>

<script>
export default {
  name: 'LinkMenu',
  props: {
    nameRoute: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    exact: {
      type: Boolean,
    },
    subLink: {
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      const el = document.querySelector('.aside-overlay')
      if (el) el.click()
    },
  },
}
</script>
