<template>
<div
  class="error error-5 d-flex flex-row-fluid bgi-size-contain bgi-position-center"
  style="background-color: white; height: 100%; background-repeat: no-repeat;"
  :style="{
    backgroundImage: `url(${backgroundImage})`
  }"
>
  <!-- begin:: Content -->
  <div
    class="container d-flex flex-row-fluid flex-column justify-content-md-center p-12"
  >
    <h1
      class="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12"
    >
      Oops!
    </h1>
    <p class="font-weight-boldest display-4">
      Something went wrong here.
    </p>
    <p class="font-size-h3">
      We're working on it and we'll get it fixed
      <br>as soon possible. <br>You can back or use our Help Center.
    </p>
  </div>
  <!-- end:: Content -->
</div>
</template>

<script>
export default {
  name: 'ErrorContainer',
  computed: {
    backgroundImage() {
      return `${process.env.BASE_URL}media/error/bg5.jpg`
    },
  },
  mounted() {},
}
</script>
<style lang="scss">
  @import '@/assets/sass/pages/error/error.scss';
</style>
