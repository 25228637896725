<script>
import Error from './ErrorContainer'

export default {
  name: 'ErrorBoundary',
  data() {
    return {
      error: false,
      errorMessage: '',
    }
  },
  watch: {
    $route() {
      this.error = false
    },
  },
  errorCaptured(err, vm, info) {
    console.log('errorCaptured', err, vm, info)
    this.error = true
    this.errorMessage = `Sorry, error occured in ${info}`

    return false
  },
  render(h) {
    if (this.error) {
      return h(Error)
    }

    return this.$slots.default[0]
  },
}
</script>
