<template>
<div>
  <div
    class="d-flex flex-column pt-8 bgi-size-cover bgi-no-repeat rounded-top"
    style="min-height: 95px;"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <h4 class="d-flex pl-8 rounded-top">
      <span class="text-white">{{ $t('CURRENCY.LIST_OF_CURRENCY') }}</span>
    </h4>
    <perfect-scrollbar
      class="scroll cursor-pointer"
      style="max-width: 350px; position: relative;"
    >
      <ul
        class="nav nav-bold nav-tabs flex-nowrap nav-tabs-line nav-tabs-line-transparent-white nav-tabs-line-active-border-primary px-8"
        role="tablist"
        style="max-width: 350px; border: none"
      >
        <li class="nav-item" v-for="(item, index) in items" :key="index">
          <a
            @click="tabIndex = index"
            class="nav-link cursor-pointer"
            :class="[{'active' : tabIndex === index}, {'mr-5': items.length -1 === index}]"
            style="white-space: nowrap;"
            :style="tabIndex === index ? 'font-weight: 600' : ''"
          >
            {{ item.provider }}
          </a>
        </li>
      </ul>
    </perfect-scrollbar>
  </div>
  <b-overlay :show="loading" rounded="sm">
    <b-tabs class="hide-tabs" style="min-height: 150px" v-model="tabIndex">
      <b-tab v-for="item in items" :key="item.provider" class="p-2">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 300px; position: relative;"
        >
          <Table v-cloak :fields="fields" :items="item.rates" :busy.sync="loading">
            <template #cell(currency)="data">
              <div class="d-flex" style="min-width: 80px">
                <span class="text-info font-weight-bolder font-size-lg mb-0">{{ data.item.currency }}</span>
              </div>
            </template>
            <template #cell(sale)="data">
              <div class="d-flex" style="min-width: 80px">
                <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">
                  {{ data.item.sale }}
                </div>
                <div v-if="data.item.sale_diff" class="d-flex font-size-xs" :class="{'align-items-end': data.item.sale_diff < 0}">
                  <div class="d-flex align-center">
                    <!--<template>-->
                    <!--<span class="svg-icon svg-icon-success svg-icon-xs">-->
                    <!--<inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg"></inline-svg>-->
                    <!--</span>-->
                    <!--</template>-->
                    <span v-if="data.item.sale_diff" class="text-success pl-2">+ {{ data.item.sale_diff }}</span>
                    <span v-else class="text-danger pl-2">- {{ data.item.sale_diff }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(buy)="data">
              <div class="d-flex" style="min-width: 80px">
                <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.buy }}</div>
                <div v-if="data.item.buy_diff" class="d-flex font-size-xs" :class="{'align-items-end': data.item.buy_diff < 0}">
                  <div class="d-flex align-center">
                    <span v-if="data.item.buy_diff" class="text-success pl-2">+ {{ data.item.buy_diff }}</span>
                    <span v-else class="text-danger pl-2">- {{ data.item.buy_diff }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Table>
        </perfect-scrollbar>
      </b-tab>
    </b-tabs>
  </b-overlay>
</div>
</template>

<script>
import Repo from '@/core/repository/common/currencyRepository'
import Table from '../../../../components/UI/Table'

export default {
  name: 'KTDropdownNotification',
  components: { Table },
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        {
          key: 'currency',
          label: this.$t('CURRENCY.CURRENCY'),
          sortable: false,
        },
        {
          key: 'sale',
          label: this.$t('CURRENCY.SALE'),
          sortable: false,
        },
        {
          key: 'buy',
          label: this.$t('CURRENCY.BUY'),
          sortable: false,
        },
      ],
      tabIndex: 0,
    }
  },
  computed: {
    backgroundImage() {
      return `${process.env.BASE_URL}media/bg/bg-10.jpg`
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Repo.list(this.params).then(({ data }) => {
        this.items = data.payload
        this.loading = false
      }).catch(() => { this.loading = false })
    },
  },
}
</script>

<style lang='scss'>
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>
