<template>
<!-- begin::Page loader -->
<div class="page-loader page-loader-logo">
  <img
    :src="logo" width="100"
    alt="Logo"
  >
  <div
    :class="spinnerClass || 'spinner-primary'"
    class="spinner"
  />
</div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: 'Loader',
  props: {
    logo: String,
    spinnerClass: String,
  },
}
</script>
