var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":{ name: _vm.nameRoute },"exact":_vm.exact},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var route = ref.route;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
      isActive && 'menu-item-active',
      isExactActive && 'menu-item-active',
      route.path === _vm.$route.path && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.onClick}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[(_vm.subLink)?_c('i',{staticClass:"menu-bullet menu-bullet-dot"}):_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":_vm.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }